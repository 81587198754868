import React, { useState } from 'react'
import './../../css/Listas.css'
import ListLine from './ListLine'
import { getScreenData } from '../../Funcoes/AreaFuncs'
import GlobalStateContext from '../GlobalStateContext'
import { useContext } from 'react'

function Listas() {
  const {data, area, screen } = useContext(GlobalStateContext)
  const [cod, setChangedCod] = useState('');

  const handleCountChange = (c) => {
    console.log('Count mudou:', c);
    if (c === cod){
      setChangedCod(''+c+'1')
    }else{
      setChangedCod(c)
    }
  };

  return (
    <div className='lista'>
        <div className='colunas'>
          <div className='coluna'>
            {data && getScreenData(area, screen, data).map((item, idx) => {
              if (item.col === 1){
                return(
                  <div >
                   <section>{item.sectionName}</section>
                   <div  className='sectionBox'>
                      {item.props.map((cod, index) => {
                        if (cod !== ''){
                          return (
                            <ListLine ipt={item.ipt[index]} cod={cod} handleCountChange={handleCountChange} qty={!item.qty ? 0 : item.qty[index]} key={cod}/>
                          )
                        }else{return(<></>)}
                      })}
                   </div>
                  </div>
                )
              }else{return(<></>)}
            })}
            <div style={{height: '300px'}}></div>
          </div>
          <div className='coluna'>
          {data && getScreenData(area, screen, data).map((item, idx) => {
              if (item.col === 2){
                return(
                  <div >
                   <section>{item.sectionName}</section>
                   <div className='sectionBox'>
                      {item.props.map((cod, index) => {
                        if (cod !== ''){
                          return (
                            <ListLine ipt={item.ipt[index]} cod={cod}  handleCountChange={handleCountChange} qty={!item.qty ? 0 : item.qty[index]} key={cod}/>
                          )
                        }else{return(<></>)}
                      })}
                   </div>
                  </div>
                )
              }else{return(<></>)}
            })}
          </div>
        </div>
    </div>
  )
}

export default Listas
  