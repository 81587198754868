import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SegmentedControl from "../../components/ui/Segment";


function ComboLine({cod, handleCountChange}) {
  const { setAlert, data } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(parseInt(data[cod].valor));

  

  useEffect(() => {
    setSelectedValue1(`${parseInt(data[cod]?.valor)}`) 
  }, [ cod, data]);


  const updateValue = async (val) => {
      setSelectedValue1(val)
      console.log(''+val + ': '+cod)
      await fetch('HTTP://localhost:7096/1/setValue', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({codigo: cod, newValue: val})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro de requisição.');
      }else{
        console.log('updated: '+val)
        data[cod].valor = val
        setAlert(true)
        handleCountChange(cod)
      }
    })
    .catch(error => {
      // Lida com o erro da requisição
    })
  }

 

  const getSeg = () => {
    let max = data[cod].limiteMax
    let q = 6
    if (max !== null){
      q = max
    }
    console.log('Max: '+ max)
    switch(q){
      case 2: return getSeg02
      case 3: return getSeg03
      case 4: return getSeg04
      case 5: return getSeg05
      case 6: return getSeg06
      default: return getSeg01
    }
  }

  // let seg1List = ['opCalcCaldeirasVM', 'opTurboBombaCaldeirasVM']
  // let seg2List = ['qtdeCozMassaA', 'opTurboBombaCaldeirasVM']
  

  const getSeg01 = [
    {
      label: "0",
      value: 0,
      ref: useRef()
    },
    {
      label: "1",
      value: 1,
      ref: useRef()
    }
  ]
  const getSeg02 = [
    {
      label: "0",
      value: 0,
      ref: useRef()
    },
    {
      label: "1",
      value: 1,
      ref: useRef()
    }
    ,
    {
      label: "2",
      value: 2,
      ref: useRef()
    }
  ]
  const getSeg03 = [
    {
      label: "0",
      value: 0,
      ref: useRef()
    },
    {
      label: "1",
      value: 1,
      ref: useRef()
    }
    ,
    {
      label: "2",
      value: 2,
      ref: useRef()
    },
    {
      label: "3",
      value: 3,
      ref: useRef()
    }
  ]

  const getSeg04 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3", value: 3, ref: useRef()},
    {label: "4", value: 4, ref: useRef()}
  ]
  const getSeg05 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3", value: 3, ref: useRef()},
    {label: "4", value: 4, ref: useRef()},
    {label: "5", value: 5, ref: useRef()}
  ]
  const getSeg06 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3", value: 3, ref: useRef()},
    {label: "4", value: 4, ref: useRef()},
    {label: "5", value: 5, ref: useRef()},
    {label: "6", value: 6, ref: useRef()}
  ]

  return (
    <SegmentedControl
      descricao={data[cod].descricao}
        name="group-1"
        callback={(val) => updateValue(val)}
        controlRef={useRef()}
        defaultIndex={selectedValue1}
        segments={getSeg()}
      />
    // <div className='inputLineCont'>
    //         <div className='line'>
    //             <div className='desc'>{data[cod]?.descricao}</div>
    //             <div className='iptCombo'>
    //                 <select id={cod} value={selectedValue} onChange={updateValue}>
    //                     <option value="0">0</option>
    //                     <option value="1">1</option>
    //                     <option value="2">2</option>
    //                     <option value="3">3</option>
    //                 </select>
    //             </div>

    //             {/* <div className='iptCont'> */}
    //                 {/* <input className='ipt' value={v} id={cod} onChange={handlePrevV} onBlur={updateValue} onFocus={handlePrevV} type="text" /> */}
    //             {/* </div> */}
    //             <div className='unit'>{data[cod]?.unidade}</div>
    //         </div>
    // </div>
  )
}

export default ComboLine

