import React from 'react';
import './../../css/Modal.css'; // Arquivo de estilos para o modal

const Modal = ({ show, progress, texto}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className='modal-text'>{texto}</div>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: progress + '%' }}></div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
