function getFilteredData(filter, data){
    console.log('Filter: '+filter)
    let listFilteredData = []
      if (!data){
        return listFilteredData
      }
      let listKeys = Object.keys(data)
      
      listKeys.map((item) => {
          if (removerAcentos(data[item].descricao.toLowerCase()).includes(removerAcentos(filter.toLowerCase())) || removerAcentos(data[item].codigo.toLowerCase()).includes(removerAcentos(filter.toLowerCase()))){
                listFilteredData.push(data[item])
            }
      })
      return listFilteredData.sort(compararPorColuna('descricao'))
  }
  
  module.exports = {
    getFilteredData
    }

    function removerAcentos(string) {
        return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const compararPorColuna = (coluna) => (a, b) => {
      if (a[coluna] < b[coluna]) return -1;
      if (a[coluna] > b[coluna]) return 1;
      return 0;
    };