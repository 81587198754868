
import styles from './BarraSuperior.module.css'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import { IoRefreshCircle, IoAlertCircleSharp } from "react-icons/io5";
import React, { useState, useContext } from 'react'
import Modal from './Modal';
import GlobalStateContext from '../GlobalStateContext'
import FileUploader from '../../Resources/FileUploader';


function BarraSuperior() {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('Calculando...');
  const { isAlertOn, setAlert, setData, area } = useContext(GlobalStateContext);

  const hanldeAlert = (value) => {
    setAlert(value)
  }
 
  const fetchData = async () => {
    setIsLoading(true)
    setModalText('Calculando...')
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue <= 100) {
        setProgress(progressValue)
      } else {
        clearInterval(interval)
        setModalText('Atualizando...')
      }
    }, 40);

    try {
      const response = await fetch('HTTP://localhost:7096/1/calcAll');
      if (!response.ok) {
        throw new Error('Erro ao buscar dados da API');
      }
      const jsonData = await response.json()
      setData(jsonData)
      setIsLoading(false)
      setProgress(0)
      hanldeAlert(false)
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      setIsLoading(false)
      setProgress(0)
    }
  }

  const calcBME = () => {
    fetchData()
  }

  return (
    <div className={styles.barra_Superior}>
      <div className={styles.menu_BarraSup}>
        <div className={styles.buttom}>
            <IoRefreshCircle className={styles.menu_Item} onClick={calcBME}/>
            <div className={styles.iconText}>Calcular</div>
            {isAlertOn ? <div className={styles.badge}><IoAlertCircleSharp/></div>:<></>}
        </div>
        <div className={styles.buttom}>
            <FileUploader/>
        </div>
      </div>
      <h2 className={styles.title_BarraSup}>{getTitleFromArea(area)}</h2>
      <div className={styles.logos_BarraSup}>
        <img className={styles.logo_BarraSup} src="/logo512.png" alt="logo pms"/>
      </div>
      <Modal show={isLoading} progress={progress} texto={modalText}/>
    </div> 
  )
}

export default BarraSuperior

