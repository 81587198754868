import React, { useState, ScrollView } from 'react'
import GlobalStateContext from '../../GlobalStateContext'
import { useContext } from 'react'
import LimitsLine from './LimitsLine'
import { getFilteredData } from '../../../Funcoes/GetFilteredData'

function Limites() {
const {data} = useContext(GlobalStateContext)
const [filtro, setFiltro] = useState('')
const [filteredData, setFilteredData] = useState(getFilteredData('', data))

const handleFilter = (e) => {
  console.log('Sender: '+e.target.value)
  setFiltro(e.target.value)
  setFilteredData(getFilteredData(e.target.value, data))
}

  return (
    <div style={{overflowY: 'scroll', width: '98%', margin: 'auto', marginTop: '1vh'}}>
        <div style={{textAlign: 'left', margin: '2vh', height: '3vh'}}>
          <input value={filtro} type="text" onChange={handleFilter} style={{width: '30%', height: '3vh'}}/>
        </div>
        <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh', marginBottom: '0.5vh', fontWeight: 'bold'}}>
            <div className='fullLine'>
                <div className='fullLineRow' style={{width: '30%'}}>Descrição</div>
                <div className='fullLineRow' style={{width: '25%'}}>Código</div>
                <div className='fullLineRow' style={{width: '15%'}}>Valor Atual</div>
                <div className='fullLineRow' style={{width: '15%'}}>Valor Default</div>
                <div className='fullLineRow' style={{width: '15%'}}>Limite Mínimo</div>
                <div className='fullLineRow' style={{width: '15%'}}>Limite Máximo</div>
                <div className='fullLineRow' style={{width: '15%', textAlign: 'right'}}>Unidade</div>
            </div>
      </div>
        {filteredData.map((item) => {
              return (<LimitsLine item={item} key={item.codigo}/>)
        })}
    </div>
  )
}

export default Limites