module.exports = {
    getScreenData
}
    

function getScreenData(area, screen, data){
    if (area === 'resumoProd'){
      return resProd
    } 

    switch (''+area+','+screen){
        case 'extracao,fluxos': return extFlows
        case 'extracao,equipamentos': return extEquips(data)
        case 'tratAcucar,fluxos': return tratAcFlows
        case 'tratAcucar,equipamentos': return tratAcEquips(data)
        case 'tratEtanol,fluxos': return tratEtFlows
        case 'tratEtanol,equipamentos': return tratEtEquips
        case 'filtLodo,equipamentos': return filtEquips
        case 'evaporacao,fluxos': return evapFlows
        case 'evaporacao,equipamentos': return evapEquips
        case 'fermentacao,equipamentos': return fermEquips
        case 'fermentacao,fluxos': return fermFlows
        case 'destilaria,premissas': return destPremissas(data)
        case 'destilaria,equipamentos': return destEquips(data)
        case 'fabrica,premissas': return fabPrem
        // case 'fabrica,fluxos': return fabFlows
        case 'fabrica,equipamentos': return fabEquips
        case 'vapor,premissas': return vapPrem(data)
        case 'vapor,equipamentos': return vapEquips(data)
        case 'balHidrico,premissas': return bhPrem
        case 'balHidrico,equipamentos': return bhEquips
        default: return [{}]
    }
}

const resProd = 
  [
    { 
      col: 1,
      sectionName: 'MOAGEM',
      props: ['flowCana', 'artCana', 'fibraCana', 'fibraEmbeb'],
      ipt: [false,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'PRODUÇÃO AÇÚCAR',
      props: ['mixAcucar', 'flowAcucar', 'flowAcucarScsdia', 'flowAcucarScsTc', 'flowAcucarTondia', 'recupSJM'],
      ipt: [false,false,false,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'PRODUÇÃO ETANOL',
      props: ['flowVolEtanolProd100Dia', 'flowEtanolDia', 'flowEtanolHidDia', 'flowEtanolTanqueToDesidDia', 'mixEtanolAnidro', 'flowEtanolAnidroDia', 'flowEtanolHidAnidro',
      'efFermentacao', 'efDestilaria', 'rgd'],
      ipt: [false,false,false,false,false,false,false,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'EVAPORAÇÃO / FÁBRICA',
      props: ['taxaEvapPreEvapAcucar', 'taxaEvap2EfEvapAcucar', 'taxaMediaEvapUltimosEf', 'ciclosMassaA', 'ciclosMassaB'],
      ipt: [false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'ENERGIA',
      props: ['expEnergia', 'consEnergia', 'expEnergiaDia', 'consEnergiaDia', 'expEnergiaTC', 'consEspEnergiaTC'],
      ipt: [false,false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'BAGAÇO',
      props: ['flowBagaco', 'umidBagaco', 'sobraBagaco'],
      ipt: [false,false,false]
    },
    { 
      col: 2,
      sectionName: 'PERFORMANCE',
      props: ['rtc', 'efIndustrial', 'artProdutos', 'consVaporTC', 'consAguaTC'],
      ipt: [false,false,false,false,false]
    }
    ,
    { 
      col: 2,
      sectionName: 'PERDAS',
      props: ['perdaArtExtracao', 'perdaFermDest', 'perdaArtLavagemCana', 'perdaArtTorta', 'perdaArtMultijatoFabrica', 'perdaArtAguaResiduaria', 'perdaArtIndeterminda', 'perdaTotalArt'],
      ipt: [false,false,true,true,true,true,true,false]
    }
  ]

const extFlows = 
  [
    { 
      col: 1,
      sectionName: 'CANA',
      props: ['flowCana', 'artCana', 'purezaCana', 'fibraCana', 'arCana', 'tempCana','polCana', 'brixCana'],
      ipt: [true,true,true,true,true,true,false,false]
    },
    { 
      col: 1,
      sectionName: 'EMBEBIÇÃO',
      props: ['flowEmbeb', 'fibraEmbeb', 'tempCondpEmbeb', 'tempEmbeb'],
      ipt: [false,true,true,true]
    },
    { 
      col: 2,
      sectionName: 'BAGAÇO',
      props: ['flowBagaco', 'efExtMoenda', 'fibraBagaco', 'umidBagaco', 'brixBagaco', 'artBagaco','polBagaco'],
      ipt: [false,true,true,true,true,false,false]
    },
    { 
      col: 2,
      sectionName: 'CALDO',
      props: ['caldoMistoToAcucar', 'caldoFiltToAcucar', 'tempCaldoMisto'],
      ipt: [true,true,true]
    },
    { 
      col: 2,
      sectionName: 'OUTROS',
      props: ['consCondVGLimpPeneira', 'consAguaBrutaLavCorrDifusor'],
      ipt: [true,true]
    }
  ]

function extEquips(data){ 
    return [
    { 
      col: 1,
      sectionName: 'AQUECIMENTO',
      props: [
        'qtdeOpAqDifusor', 'areaAqDifusor', 'coefTrocaAqDifusor', 'relCaldoEscCana', 'flowCaldoEscaldante', 'tempCaldoEscaldante','tempOutAqDifusor', 'utilAqDifusor',
        data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2AquecDifusor' : 'consVaporVV1AquecDifusor', 
        data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2BorbDifusor' : 'consVaporVV1BorbDifusor', 
        data['vaporUtilizadoDifusor'].valor === 0 ? 'consVaporVV2AquecDirDifusor' : 'consVaporVV1AquecDirDifusor', 
        data['vaporUtilizadoDifusor'].valor === 0 ? 'consVV2TotalDifusor' : 'consVV1TotalDifusor', 
    ],
      ipt: ['combo',true,true,true,false,true,true,false,false,true,false,false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO NIVELADOR',
      props: ['tempVaporVENivelador', 'consEspEnergiaNivelador', 'consEspVaporNivelador', 'consVaporVMNivelador', 'ptNivelador'],
      ipt: [true,true,true,false,false]
    },
    { 
      col: 1,
      sectionName: 'ACIONAMENTO PICADOR',
      props: ['tempVaporVEPicador', 'consEspEnergiaPicador', 'consEspVaporPicador', 'consVaporVMPicador', 'ptPicador'],
      ipt: [true,true,true,false,false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO DESFIBRADOR',
      props: ['tempVaporVEDesfibrador', 'consEspEnergiaDesfibrador', 'consEspVaporDesfibrador', 'consVaporVMDesfibrador', 'ptDesfibrador'],
      ipt: [true,true,true,false,false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO TERNO DESAGUADOR',
      props: ['tempVaporVEAcion12T', 'consEspEnergiaAcion12T', 'consEspVaporAcion12T', 'consVaporAcion12T', 'ptAcion12T'],
      ipt: [true,true,true,false,false]
    },
    { 
      col: 2,
      sectionName: 'ACIONAMENTO TERNO SECAGEM',
      props: ['tempVaporVEAcion34T', 'consEspEnergiaAcion34T', 'consEspVaporAcion34T', 'consVaporAcion34T', 'ptAcion34T'],
      ipt: [true,true,true,false,false]
    }
  ]
}

const tratAcFlows = 
  [
    { 
      col: 1,
      sectionName: 'DOSAGEM CAL',
      props: ['grausBaume', 'dosagemCalAcucar'],
      ipt: [true,true]
    },
    { 
      col: 1,
      sectionName: 'DOSAGEM POLIMERO',
      props: ['propDosagemPolAcucar'],
      ipt: [true]
    },
    { 
      col: 2,
      sectionName: 'DECANTAÇÃO',
      props: ['lodoCaldoFlashAcucar', 'quedaTempDecantador'],
      ipt: [true,true]
    }
  ]

function tratAcEquips(data) {
return [
    { 
      col: 1,
      sectionName: 'REGENERADOR CALDO x VINHAÇA',
      props: ['opRegCaldoVinhaca', 'tempVinhaca', 'tempVinhacaReg', 'tempOutCaldoRegCaldoVinhaca', 'regCvCTareaTroca','uRegCaldoVinhaca', 'regCvCTareaTrocaTotal','regCvCTareaReq', 'mldtRegCaldoVinhaca'],
      ipt: ['combo',true,true,false,true,true,false,false,false]
    },
    { 
      col: 1,
      sectionName: 'REG. CALDO x CONDENSADO VEGETAL GERAL',
      props: ['opRegCaldoCond', 'tempCondVG', 'tempCondReg', 'tempOutCaldoRegCaldoCond', 'regCxCVGCTareaTroca', 'uRegCaldoCond', 'regCxCVGCTareaTrocaTotal', 'regCxCVGCTareaReq', 'mldtRegCaldoCond'],
      ipt: ['combo',true,true,false,true,true,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV2',
      props: ['qtdeOpAqVV2Acucar', 'tempOutAqVV2Acucar', 'utilAqVV2Acucar', 'consVaporVV2AqAcucar', 'coefTrocaAqVV2Acucar', 'areaAqVV2Acucar'],
      ipt: ['combo',true,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV1',
      props: ['qtdeOpAqVV1Acucar', 'tempOutAqVV1Acucar', 'utilAqVV1Acucar', 'consVaporVV1AqAcucar', 'coefTrocaAqVV1Acucar', 'areaAqVV1Acucar'],
      ipt: ['combo',true,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO CALDO CLARIFICADO',
      props: ['qtdeOpAqCCAcucar', 'opVaporAqCCAcucar', 'tempOutAqCCAcucar', 'utilAqCCAcucar', 
      data['opVaporAqCCAcucar'].valor === 1 ? 'consVaporVV2AqCCAcucar' : data['opVaporAqCCAcucar'].valor === 2 ? 'consVaporVV1AqCCAcucar' : 'consVaporVEAqCCAcucar',
      'coefTrocaAqCCAcucar', 'areaAqCCAcucar'],
      ipt: ['combo','combo',true,false,false,true,true]
    }
  ]
}

const tratEtFlows = 
  [
    { 
      col: 1,
      sectionName: 'DOSAGEM CAL',
      props: [ 'dosagemCalEtanol'],
      ipt: [true]
    },
    { 
      col: 1,
      sectionName: 'DOSAGEM POLIMERO',
      props: ['propDosagemPolEtanol'],
      ipt: [true]
    },
    { 
      col: 2,
      sectionName: 'DECANTAÇÃO',
      props: ['lodoCaldoFlashEtanol', 'quedaTempDecantador'],
      ipt: [true,true]
    }
  ]

const tratEtEquips = [
    { 
      col: 1,
      sectionName: 'AQUECIMENTO COM VFL',
      props: ['qtdeOpAqVFLEtanol', 'utilVFLEtanol', 'tempOutAqVFLEtanol', 'utilAqVFLEtanol', 'consVaporVFLAqEtanol','coefTrocaAqVFLEtanol', 'areaAqVFLEtanol'],
      ipt: ['combo',true,false,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV2',
      props: ['qtdeOpAqVV2Etanol', 'tempOutAqVV2Etanol', 'utilAqVV2Etanol', 'consVaporVV2AqEtanol', 'coefTrocaAqVV2Etanol', 'areaAqVV2Etanol'],
      ipt: ['combo',true,false,false,true,true]
    },
    { 
      col: 2,
      sectionName: 'AQUECIMENTO COM VV1',
      props: ['qtdeOpAqVV1Etanol', 'tempOutAqVV1Etanol', 'utilAqVV1Etanol', 'consVaporVV1AqEtanol', 'coefTrocaAqVV1Etanol', 'areaAqVV1Etanol'],
      ipt: ['combo',true,false,false,true,true]
    }
]

const filtEquips = [
  { 
    col: 1,
    sectionName: 'GERAL',
    props: ['prodTorta', 'perdaArtTorta', 'tempCaldoFiltrado', 'relBagacilhoCana'],
    ipt: [true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'FILTRO ROTATIVO',
    props: ['embLodoFitroRot', 'relFiltroRotativo', 'flowAguaEmbebicaoFiltroRot', 'flowLodoFiltroRotativo'],
    ipt: [true,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'FILTRO PRENSA',
    props: ['embLodoFiltroPrensa', 'flowAguaLavTelasFiltroPre', 'relFiltroPrensa', 'flowAguaEmbebicaoFiltroPre', 'flowLodoFiltroPrensa'],
    ipt: [true,true,true,false,false]
  }
]

const evapFlows = [
  { 
    col: 1,
    sectionName: 'XAROPE',
    props: ['brixXarope', 'desvioXaropeToEtanol'],
    ipt: [true,true]
  },
  { 
    col: 2,
    sectionName: 'DESVIO CALDO 3º EFEITO',
    props: ['flowSangriaCaldo3Ef'],
    ipt: [true]
  }
]

const evapEquips = [
  { 
    col: 1,
    sectionName: 'EVAPORAÇÃO AÇÚCAR',
    props: ['areaOpPreEvapAcucar', 'areaOp2EfEvapAcucar', 'areaOp3EfEvapAcucar', 'areaOp4EfEvapAcucar', 'areaOp5EfEvapAcucar'],
    ipt: [true,true,true,true,true]
  },
  { 
    col: 2,
    sectionName: 'DESVIO CALDO 3º EFEITO',
    props: ['flowSangriaCaldo3Ef'],
    ipt: [true]
  }
]

const fermEquips = [
  { 
    col: 1,
    sectionName: 'RESFRIAMENTO MOSTO',
    props: ['tempMostoReg', 'tempMostoResf', 'tempAguaFriaTorreFerm', 'tempAguaSaiResfMosto', 'coefTrocaResfMosto', 'flowAguaResfMosto', 'approachResfMosto', 'areaNecessariaResfMosto'],
    ipt: [false,true,false,true,true,false,false,false]
  },
  { 
    col: 1,
    sectionName: 'RESFRIAMENTO DORNAS',
    props: ['tempAguaFriaTorreFerm', 'tempAguaQuenteTorreFerm', 'flowAguaTorreFerm'],
    ipt: [false,false,false]
  },
  { 
    col: 2,
    sectionName: 'CENTRÍFUGAS',
    props: ['qtdeCentrifugas', 'capacCentrifugas', 'eficCentrifugas', 'sobraCentrifugas'],
    ipt: ['combo',true,true,false]
  }
]

const fermFlows = [
  { 
    col: 1,
    sectionName: 'PREMISSAS',
    props: ['brixMosto', 'concCelVinhoBruto', 'concCelCreme', 'concCelVinhoCent', 'taxaReciclo', 'rendCelula', 'efFermentacao'],
    ipt: [true,true,true,true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'DILUIÇÃO MOSTO',
    props: ['consCondContDilMosto', 'tempAguaDilMosto', 'consAguaTratPrepMosto', 'flowAguaDilMosto'],
    ipt: [true,true,false,false]
  },
  { 
    col: 2,
    sectionName: 'TEMPERATURAS',
    props: ['tempXarope', 'tempMelFinal', 'tempMostoResf', 'tempAguaDilMosto'],
    ipt: [true,true,true,true]
  }
]

function destPremissas(data){
  return [
    { 
      col: 1,
      sectionName: 'GERAL',
      props: ['relEtanolSegProd', 'relOleoFuselProd', 'relPerdaDegasProducao', 'concEtanolVinhaca', 'concEtanolFlegmaca', 'concEtanolFlegma'],
      ipt: [true,true,true,true,true,true]
    },
    { 
      col: 1,
      sectionName: 'CONSUMO VAPOR',
      props: ['consEspVaporDestManual', 'consEspVaporDestilaria', 'glVinhoBoletim', 'glVinhoConsVapor'],
      ipt: ['combo',
      data['consEspVaporDestManual'].valor === 0 ? false : true,
      true,'combo']
    },
    { 
      col: 2,
      sectionName: 'PRODUÇÃO',
      props: ['efDestilaria', 'flowVolEtanolProd100', 'flowEtanol', 'flowEtanolHid', 'flowEtanolAnidro'],
      ipt: [false,false,false,false,false]
    },
    { 
      col: 2,
      sectionName: 'OUTROS',
      props: ['flowFlegmaca', 'flowVinhaca'],
      ipt: [false,false]
    }
  ]
} 

function destEquips(data){
  return [
    { 
      col: 1,
      sectionName: 'COLUNA A',
      props: ['vaporColAConv', 
      // data['vaporColAConv'].valor === 0 && 'isAquecIndConv',
      // data['isAquecIndConv'].valor === 1 && 'taxaAquecIndConv',
      // 'relEtanolHidConvencional', 
      'consEspVaporColunaA', 'consEspVaporRepColunaA', 
      data['vaporColAConv'].valor === 0 ? 'flowVaporVV1ColAConv' : 'flowVaporVEColAConv',
    ],
      ipt: ['combo',
      // data['vaporColAConv'].valor === 0 && 'combo',
      // data['isAquecIndConv'].valor === 1 && true,
      // true,
      false,true,false]
    },
    { 
      col: 1,
      sectionName: 'COLUNA B',
      props: ['consEspVaporColunaB', 
      data['vaporColB'].valor === 0 ? 'consVaporVV1ColB' : 'consVaporVEColB'
    ],
      ipt: [true,false]
    },
    { 
      col: 2,
      sectionName: 'DESIDRATAÇÃO',
      props: ['flowEtanolAnidroDia', 'mixEtanolAnidro', 'consEspV22Anidro', 'consEspVEAnid', 'repAnidro', 'flowEtanolTanqueToDesid', 'consVaporVEDesidratacao', 'consVaporV22Desidratacao'],
      ipt: [true,false,true,true,'combo',false,false,false]
    }
  ]
} 

const fabPrem = [
  { 
    col: 1,
    sectionName: 'PUREZA',
    props: ['purezaXarope', 'purezaMelARico', 'purezaMelAPobre', 'purezaMelFinal', 'purezaAcucar', 'purezaAcucarB', 'purezaMagma'],
    ipt: [true,true,true,true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'BRIX',
    props: ['brixMassaA', 'brixMelARico', 'brixMARDiluido', 'brixMelAPobre', 'brixMAPDiluido', 'brixMelFinal', 'brixMagma', 'brixMassaB', 'brixAcucarB', 'brixAcucar'],
    ipt: [true,true,true,true,true,true,true]
  },
  { 
    col: 2,
    sectionName: 'DIVISÃO DE XAROPE',
    props: ['xaropeToMassaA', 'xaropeToMassaB'],
    ipt: [true, false]
  },
  { 
    col: 2,
    sectionName: 'OPCÃO VAPOR',
    props: ['qtdeCozMassaAVV1'],
    ipt: ['combo']
  },
  { 
    col: 2,
    sectionName: 'CALCULOS',
    props: ['recupSJM', 'concCristaisMassaA', 'concCristaisMassaB'],
    ipt: [false,false,false]
  }
]

// const fabFlows = [
//   { 
//     col: 1,
//     sectionName: 'PUREZA',
//     props: ['purezaXarope', 'purezaMelARico', 'purezaMelAPobre', 'purezaMelFinal', 'purezaAcucar', 'purezaAcucarB', 'purezaMagma'],
//     ipt: [true,true,true,true,true,true,true]
//   },
//   { 
//     col: 1,
//     sectionName: 'BRIX',
//     props: ['brixMassaA', 'brixMelARico', 'brixMARDiluido', 'brixMelAPobre', 'brixMAPDiluido', 'brixMelFinal', 'brixMagma', 'brixMassaB', 'brixAcucarB', 'brixAcucar'],
//     ipt: [true,true,true,true,true,true,true]
//   },
//   { 
//     col: 2,
//     sectionName: 'DIVISÃO DE XAROPE',
//     props: ['xaropeToMassaA', 'xaropeToMassaB'],
//     ipt: [true, false]
//   },
//   { 
//     col: 2,
//     sectionName: 'OPCÃO VAPOR',
//     props: ['qtdeCozMassaAVV1'],
//     ipt: ['combo']
//   },
//   { 
//     col: 2,
//     sectionName: 'CALCULOS',
//     props: ['recupSJM', 'concCristaisMassaA', 'concCristaisMassaB'],
//     ipt: [false,false,false]
//   }
// ]

const fabEquips = [
  { 
    col: 1,
    sectionName: 'COZEDORES MASSA A',
    props: ['qtdeCozMassaA', 'capCozMassaA', 'ciclosMassaA'],
    ipt: ['combo',true,false],
  },
  { 
    col: 1,
    sectionName: 'COZEDORES MASSA B',
    props: ['qtdeCozMassaB', 'capCozMassaB', 'ciclosMassaB'],
    ipt: ['combo',true,false],
  },
  { 
    col: 2,
    sectionName: 'CENTRÍFUGAS MASSA A',
    props: ['qtdeCentOpMassaA', 'capCentMassaA', 'ciclosCentMassaA', 'efCentMassaA', 'capTotalCentMassaA', 'sobraCentMassaA'],
    ipt: ['combo',true, true, true, false, false],
  },
  { 
    col: 2,
    sectionName: 'CENTRÍFUGAS MASSA B',
    props: ['qtdeCentOpMassaB', 'capCentMassaB', 'efCentMassaB', 'capTotalCentMassaB', 'sobraCentMassaB'],
    ipt: ['combo',true, true, false, false],
  }
]

function vapPrem(data){
  return [
    { 
      col: 1,
      sectionName: 'ENERGIA',
      props: ['expEnergia', 'expEnergiaTC', 'consEnergia', 'consEspEnergiaTC'],
      ipt: [false, false, false, true]
    },
    { 
      col: 1,
      sectionName: 'PRESSÕES',
      props: ['pressureVE', 'pressureVV1', 'pressureVV2', 'pressureVV3', 'pressureVV4', 'pressureVV5'],
      ipt: [true,true,true,true,true,true,true]
    },
    { 
      col: 1,
      sectionName: 'TEMPERATURAS',
      props: ['tempVaporVE', 'tempDesaerador', 'tempRetCondVEDesaerador', 'tempsAguaDesmiDesaerador', 'tempCondVGDesaerador'],
      ipt: [true, true, true, true, true]
    },
    { 
      col: 2,
      sectionName: 'PERDAS',
      props: ['perdasVM', 'perdasVE', 'perdasVV1', 'perdasVV2', 'perdasVV3'],
      ipt: [true, true, true, true, true]
    },
    { 
      col: 2,
      sectionName: 'ALÍVIO',
      props: ['consVaporVV1Alivio', 'consVaporVV2Alivio'],
      ipt: [true, true]
    },
    { 
      col: 2,
      sectionName: 'DESAERADOR',
      props: ['retornoCondVEProcesso', 'gerCondVETgCdVA', 'opRepoDesaerador',
      data['opRepoDesaerador'].valor === 2 ? 'relRepoDesaerador' : '',
      'consCondVGDesaerador', 'consAguaDesmiDesaerador'],
      ipt: [true, false, 'combo',
      data['opRepoDesaerador'].valor === 2 ? true : '',
      false, false]
    }
  ]
} 

function vapEquips(data){
  return [
    { 
      col: 1,
      sectionName: 'TURBO-GERADOR',
      props: [
        data['isExpEnergy'].valor === 1 ? 'opModeTgCpVM' : '',
        'ptTgCpVM',
        'consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM', 'eficIsoTgCpVM', 'consEspTgCpVM', 'pressEscTgCpVM', 'tempEscTgCpVM'],
      ipt: [
        data['isExpEnergy'].valor === 1 ? 'swicth' : '', 
        data['opModeTgCpVM'].valor === 1 ? true : false,
        false, true, true, false, false, true, true]
    },
    { 
      col: 2,
      sectionName: 'CALDEIRA',
      props: ['opCalcCaldeirasVM', 'gerVaporVMCaldeiras', 'consBagCaldeirasVM', 'pressureVM', 'tempVaporVM', 'tempAguaAlimCaldeirasVM', 'purgaDesCaldeirasVM',
      data['opCalcCaldeirasVM'].valor === 1 ? 'o2CaldeirasVM' : '',
      data['opCalcCaldeirasVM'].valor === 1 ? 'tempGasesCaldeirasVM' : '',
      data['opCalcCaldeirasVM'].valor === 1 ? 'perdasNqCaldeirasVM' : '',
      data['opCalcCaldeirasVM'].valor === 1 ? 'perdasIrrCaldeirasVM' : '',
      data['opCalcCaldeirasVM'].valor === 1 ? 'excessoArCaldeirasVM' : '',
      'rendCaldeirasVM',
      'efPCICaldeirasVM',
      'opTurboBombaCaldeirasVM',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? 'consEspTBCaldeirasVM' : '',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? 'tempVETBCaldeirasVM' : '',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? 'ptTBCaldeirasVM' : '',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? 'consVaporVMTBCaldeirasVM' : '',
      
      ],
      ipt: ['swicth', false, false, true, true, true, true,
      data['opCalcCaldeirasVM'].valor === 1 ? true : '',
      data['opCalcCaldeirasVM'].valor === 1 ? true : '',
      data['opCalcCaldeirasVM'].valor === 1 ? true : '',
      data['opCalcCaldeirasVM'].valor === 1 ? true : '',
      data['opCalcCaldeirasVM'].valor === 1 ? false : '',
      data['opCalcCaldeirasVM'].valor === 1 ? false : true,
      false,
      'swicth',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? true : '',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? true : '',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? false : '',
      data['opTurboBombaCaldeirasVM'].valor === 1 ? false : '',
    ]
    }
  ]
} 

const bhPrem = [
  { 
    col: 1,
    sectionName: 'ÁGUA BRUTA',
    props: ['consAguaBrutaGeral'],
    ipt: [true]
  },
  { 
    col: 1,
    sectionName: 'ÁGUA TRATADA',
    props: ['consAguaTratGeral', 'consAguaTratEvap', 'consAguaTratLevedura', 'consAguaTratLimpEvap', 'consAguaTratAbrandador'],
    ipt: [true,true,true,true,true]
  },
  { 
    col: 1,
    sectionName: 'CONDENSADO VEGETAL',
    props: ['consCondContUsoGeral', 'tempCaixaCondProcesso'],
    ipt: [true,true]
  },
  { 
    col: 2,
    sectionName: 'SISTEMA LAVAGEM GASES',
    props: ['relAguaVapor', 'tempAguaEntLavadorGases', 'tempAguaSaidaLavadorGases', 'flowFuligemCaldeira', 'umidFuligemCaldeira', 'flowAguaLavadorGases', 'flowAguaEvapLavadorGases', 'flowAguaFuligem', 'consAguaResRepSistFuligem'],
    ipt: [true,true,true,true,true,false,false,false,false]
  },
]

const bhEquips = [
  { 
    col: 1,
    sectionName: 'TORRE MOSTO / DORNAS / DESTILARIA',
    props: ['tempAguaFriaTorreFerm', 'tempAguaSaiResfMosto', 'tempAguaQuenteTorreFerm', 'tempAguaQuenteTorreDest', 'tempAguaQuenteTotalTorreDest', 'perdasArrasteTorreFerm',
    'perdasPurgasTorreFerm', 'flowAguaResfMosto', 'flowAguaTorreFerm', 'flowAguaTotalTorreDest', 'flowPerdaTotalArrasteTorreDest', 'flowPerdaTotalEvapTorreDest', 'gerAguaResPurgasTorreDest', 'consAguaBrutaRepTorreDest'
  ],
    ipt: [true,true,true,true,false,true,true,false,false,false,false,false,false,false]
  },
  { 
    col: 1,
    sectionName: 'TORRE VINHAÇA',
    props: ['tempVinhacaReg', 'tempVinhacaFriaTorreVinhaca', 'flowVinhaca', 'perdasArrasteTorreVinhaca', 'flowPerdaArrasteTorreVinhaca', 'flowPerdaEvapTorreVinhaca', 'flowVinhacaFria'],
    ipt: [false,true,false,true,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'TORRE FÁBRICA',
    props: ['tempAguaFriaTorreFab', 'tempAguaQuenteTorreFab', 'perdasArrasteTorreFab', 'perdasPurgasTorreFab', 'flowAguaEvapTorreFab', 'flowAguaMassaATorreFab', 'flowAguaMassaBTorreFab',
    'flowAguaTorreFab', 'flowPerdaArrasteTorreFab', 'gerAguaResPurgasTorreFab', 'consAguaBrutaRepTorreFab', 
  ],
    ipt: [true,true,true,true,false,false,false,false,false,false,false]
  },
  { 
    col: 2,
    sectionName: 'TORRE MANCAIS',
    props: ['tempAguaFriaTorreMancais', 'tempAguaQuenteTorreMancais', 'perdasArrasteTorreMancais', 'perdasPurgasTorreMancais', 'flowAguaTorreMancais', 'flowPerdaEvapTorreMancais', 'flowPerdaArrasteTorreMancais', 
    'gerAguaResPurgasTorreMancais', 'consAguaBrutaRepTorreMancais'],
    ipt: [true,true,true,true,true,false,false,false,false]
  },
]

