import Flow from './Flow'
import ExtFlx from '../../assets/fluxogramas/extFlx.png'
import TratAcFlx from '../../assets/fluxogramas/tratAcFlx.png'
import TratEtFlx from '../../assets/fluxogramas/tratEtFlx.png'
import FiltLodoFlx from '../../assets/fluxogramas/filtFlx.png'
import EvapFlx from '../../assets/fluxogramas/evapFlx.png'
import FermFlx from '../../assets/fluxogramas/fermFlx.png'
import DestFlx from '../../assets/fluxogramas/destFlx.png'
import FabFlx from '../../assets/fluxogramas/fabFlx.png'
import VaporFlx from '../../assets/fluxogramas/vaporFlx.png'
import BhFlx from '../../assets/fluxogramas/bhFlx.png'
import bhFlx from '../../assets/fluxogramas/BH7.svg'
import vapFlx from '../../assets/fluxogramas/Vapor.svg'

import GlobalStateContext from '../../components/GlobalStateContext'
import { useContext } from 'react';
import SegmentedControl from './SegmentedControl'

function Fluxograma() {
  const { area } = useContext(GlobalStateContext)

  const getAreaData = () => {
    switch (area){
      case 'tratAcucar': return tratAcucar
      case 'tratEtanol': return tratEtanol
      case 'filtLodo': return filtLodo
      case 'evaporacao': return evaporacao
      case 'fermentacao': return fermentacao
      case 'destilaria': return destilaria
      case 'fabrica': return fabrica
      case 'vapor': return vapor
      case 'balHidrico': return bh
      default: return extracao
    }
  }
 
  return (
    <div style={{position: 'relative', height: '100%', width: '100%', backgroundColor: 'white'}}>
      <div style={{ position: 'absolute', width: '115vh', height: '100vh', top: '55%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <img style={{position: 'absolute', height: '92vh', width: `${area === 'balHidrico' ? '121.8vh' : '105.8vh'}`, left: '0vh', top: '0vh'}} src={getAreaData().imagem} alt="" />
      { getAreaData().flows.map((item, index) => {
          return (
            <Flow flow={item.flow} x={item.x} y={item.y} align={item.align} valign={item.valign} cods={item.props} key={index}/>
          )
          })}
        
      </div>
    </div>
  )
}

export default Fluxograma
 
const extracao = {
  imagem: ExtFlx,
  flows: [
    { 
      flow: 'Cana',
      props: ['flowCana','polCana', 'purezaCana', 'fibraCana', 'arCana', 'artCana', 'brixCana', 'artMassaCana', 'tempCana'],
      x: 2.3, y: 19.2, align: 'left', valign: 'flex-start'
    },
     { 
      flow: 'Embebição',
      props: ['flowEmbeb','fibraEmbeb', 'tempCondpEmbeb'],
      x: 87.6, y: 2.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempEmbeb'],
      x: 71.3, y: 18, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV2',
      props: ['consVV2TotalDifusor'],
      x: 87.6, y: 10.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVV2AquecDirDifusor'],
      x: 79.4, y: 12.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVV2BorbDifusor'],
      x: 59.3, y: 12.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVV2AquecDifusor'],
      x: 39, y: 10.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. VG',
      props: ['gerCondVGAqVV2Difusor'],
      x: 2.3, y: 10.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoEscaldante', 'tempCaldoEscaldante'],
      x: 48, y: 29.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqDifusor'],
      x: 36.5, y: 18, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Bagacilho',
      props: ['flowBagacilho'],
      x: 87.6, y: 34.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Bagaço',
      props: ['flowBagacoToBoilers'],
      x: 87.6, y: 41, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Limp. Peneira',
      props: ['consCondVGLimpPeneira'],
      x: 31.7, y: 40.8, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Lav. Corrente',
      props: ['consAguaBrutaLavCorrDifusor'],
      x: 63.6, y: 30.3, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Vapor Direto',
      props: ['flowVaporVMExtracao'],
      x: 2.3, y: 71.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVMNivelador'],
      x: 27, y: 73, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVMPicador'],
      x: 46, y: 73, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVMDesfibrador'],
      x: 65, y: 73, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVMMoendas'],
      x: 84, y: 73, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vapor Escape',
      props: ['flowVaporVEExtracao', 'tempVaporVEMoendas'],
      x: 87.6, y: 84.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowBagaco', 'fibraBagaco', 'polBagaco', 'umidBagaco', 'brixBagaco', 'artBagaco', 'artMassaBagaco'],
      x: 85.5, y: 41, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Filtrado',
      props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'],
      x: 87.6, y: 54.2, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Caldo Misto',
      props: ['flowCaldoMisto', 'brixCaldoMisto', 'tempCaldoMisto'],
      x: 51, y: 48, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Açúcar',
      props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'tempCaldoAcucar'],
      x: 2.3, y: 57.2, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Etanol',
      props: ['flowCaldoEtanol', 'brixCaldoEtanol', 'tempCaldoEtanol'],
      x: 2.3, y: 65.9, align: 'left', valign: 'flex-end'
    }
  ]
}

const tratAcucar = {
  imagem: TratAcFlx,
  flows: [
    { 
      flow: 'Vinhaça',
      props: ['flowVinhaca', 'tempVinhaca'],
      x: 44.6 , y: 3.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinhaça Reg.',
      props: ['flowVinhaca', 'tempVinhacaReg'],
      x: 34.2, y: 23.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutCaldoRegCaldoVinhaca'],
      x: 43, y: 16, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG Reg.',
      props: ['flowCondVGtoReg', 'tempCondReg'],
      x: 61.4, y: 23.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['flowCondVGtoReg', 'tempCondVG'],
      x: 72, y: 3.2, align: 'left', valign: 'flex-start'
    },
    // { 
    //   flow: '',
    //   props: ['tempOutCaldoRegCaldoCond'],
    //   x: 80, y: 3.3, align: 'left', valign: 'flex-start'
    // },
    { 
      flow: 'Leite de Cal',
      props: ['flowLeiteCalAcucar'],
      x: 87.9 , y: 45.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VV2',
      props: ['consVaporVV2AqAcucar'],
      x: 71.7, y: 33.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV2Acucar'],
      x: 61.7, y: 55, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV2Acucar'],
      x: 45, y: 43.6, align: 'center', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqAcucar'],
      x: 44.4, y: 33.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV1Acucar'],
      x: 15, y: 43.6, align: 'center', valign: 'flex-start'
    },
    { 
      flow: 'Sol. Polimero',
      props: ['flowAguaPolimeroAcucar'],
      x: 1.8, y: 78.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Vapor Flash',
      props: ['flowVapFlashAcucar'],
      x: 17.2, y: 64.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'tempCaldoClarifAcucar'],
      x: 70, y: 73.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqCCAcucar'],
      x: 90.6, y: 63.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CDVG',
      props: ['gerCondVGAqCCAcucar'],
      x: 80.5, y: 86, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Açúcar',
      props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'tempCaldoAcucar', 'artMassaCaldoAcucar'],
      x: 1.7, y: 13.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoSulfAcucar', 'brixCaldoSulfAcucar', 'tempCaldoSulfAcucar'],
      x:89.2, y: 28, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoAquecAcucar', 'brixCaldoAquecAcucar', 'tempCaldoAquecAcucar'],
      x: 6, y: 55, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoDosAcucar', 'brixCaldoDosAcucar', 'tempCaldoDosAcucar'],
      x: 74, y: 43.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoFlashAcucar', 'brixCaldoFlashAcucar', 'tempCaldoFlashAcucar'],
      x: 24, y: 73.2, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Clarificado',
      props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'tempCaldoClarifAcucar'],
      x: 87.6, y: 75.8, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Lodo Açúcar',
      props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'],
      x: 1.7, y: 86, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoRegAcucar', 'brixCaldoRegAcucar', 'tempCaldoRegAcucar'],
      x: 76, y: 13.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoFiltrado', 'brixCaldoRegAcucar', 'tempCaldoRegAcucar'],
      x: 40, y: 43.6, align: 'left', valign: 'flex-start'
    }
  ]
}

const tratEtanol = {
  imagem: TratEtFlx,
  flows: [
    { 
      flow: 'VFL',
      props: ['consVaporVFLAqEtanol'],
      x: 58.2 , y: 3.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVFLEtanol'],
      x: 48, y: 25, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVFLEtanol'],
      x: 70, y: 13.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempOutAqVV2Etanol'],
      x: 45, y: 43.4, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['tempOutAqVV1Etanol'],
      x: 10, y: 43.4, align: 'center', valign: 'flex-end'
    },
    { 
      flow: 'VV2',
      props: ['consVaporVV2AqEtanol'],
      x: 75.4, y: 33.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV2Etanol'],
      x: 65.2, y: 55, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVaporVV1AqEtanol'],
      x: 41.2, y: 33.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Veg.',
      props: ['gerCondVGAqVV1Etanol'],
      x: 30.8, y: 55, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Caldo Etanol',
      props: ['flowCaldoEtanol','brixCaldoEtanol','tempCaldoEtanol','artMassaCaldoEtanol'],
      x: 2.4, y: 15.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoAquecEtanol','brixCaldoAquecEtanol','tempCaldoAquecEtanol'],
      x: 6.6, y: 55, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoFlashEtanol','brixCaldoFlashEtanol','tempCaldoFlashEtanol'],
      x: 25, y: 73.2, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Clarificado',
      props: ['flowCaldoClarifEtanol','brixCaldoClarifEtanol','tempCaldoClarifEtanol'],
      x: 87, y: 75.8, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Lodo Etanol',
      props: ['flowLodoEtanol','brixLodoEtanol','artMassaLodoEtanol'],
      x: 2.4, y: 85.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Sol. Polimero',
      props: ['flowAguaPolimeroEtanol'],
      x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Vapor Flash',
      props: ['flowVapFlashEtanol'],
      x: 17.8, y: 64.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldoDosEtanol', 'brixCaldoDosEtanol', 'tempCaldoDosEtanol'],
      x: 89, y: 29, align: 'left', valign: 'flex-start'
    }
  ]
}

const filtLodo = {
  imagem: FiltLodoFlx,
  flows: [
    { 
      flow: 'Bagacilho',
      props: ['flowBagacilho'],
      x: 86.8 , y: 34.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Água Emb.',
      props: ['flowAguaEmbebicaoFiltroRot'],
      x: 86.9, y: 53.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Água Emb.',
      props: ['flowAguaEmbebicaoFiltroPre'],
      x: 2.3, y: 43.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Água Lav.',
      props: ['flowAguaLavTelasFiltroPre'],
      x: 2.3, y: 47.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Águas Resid.',
      props: ['flowAguaLavTelasFiltroPre'],
      x: 2.3, y: 55, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Lodo Etanol',
      props: ['flowLodoEtanol', 'brixLodoEtanol', 'artMassaLodoEtanol'],
      x: 86.8, y: 6.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Lodo Açúcar',
      props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'],
      x: 2.3, y: 6.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Caldo Filtrado',
      props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'],
      x: 2.3, y: 74, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Torta',
      props: ['flowTorta','artTorta', 'artMassaTorta'],
      x: 2.3, y: 83.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Lodo',
      props: ['flowLodo','brixLodo', 'artMassaLodo'],
      x: 52.8, y: 7, align: 'left', valign: 'flex-start'
    }
  ]
}

const evaporacao = {
  imagem: EvapFlx,
  flows: [
    { 
      flow: 'Sangria VV1',
      props: ['sangriaVV1'],
      x: 31.6 , y: 11, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Sangria VV2',
      props: ['sangriaVV2'],
      x: 47.4 , y: 11, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Sangria VV3',
      props: ['sangriaVV3'],
      x: 62.4 , y: 11, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV1'],
      x: 24.4 , y: 28, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV2'],
      x: 40 , y: 26.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV3'],
      x: 55 , y: 26.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV4'],
      x: 70 , y: 26.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV5'],
      x: 85 , y: 26.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldo1EfAc', 'brixCaldo1EfAc'],
      x: 20.8 , y: 52.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldo2Ef', 'brixCaldo2EfAc'],
      x: 36 , y: 52.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldo3Ef', 'brixCaldo3EfAc'],
      x: 52 , y: 52.4, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowCaldo4Ef', 'brixCaldo4EfAc'],
      x: 67.5 , y: 52.4, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowXarope', 'brixXarope'],
      x: 83.2 , y: 52.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Xarope p/ Fáb.',
      props: ['flowXaropeToFab','brixXarope'],
      x: 87.9, y: 52.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Xarope p/ Ferm.',
      props: ['flowXaropeToFerm','brixXarope'],
      x: 87.9, y: 60.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Desvio Caldo',
      props: ['flowSangriaCaldo3Ef','brixCaldo3EfAc'],
      x: 87.9, y: 66.8, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Vapor Escape',
      props: ['consVaporVEEvapAc'],
      x: 2, y: 40.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. Escape',
      props: ['gerCondVEEvapAc'],
      x: 87.9, y: 78.6, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Cond. Vegetal',
      props: ['gerCondVGEvapAc'],
      x: 87.9, y: 43.8, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Cond. VV1',
      props: ['gerCondVG1EvapAc'],
      x: 87.9, y: 73.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvapPreEvapAcucar', 'areaOpPreEvapAcucar'],
      x: 11.5, y: 24.5, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap2EfEvapAcucar', 'areaOp2EfEvapAcucar'],
      x: 29.8, y: 24.6, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap3EfEvapAcucar', 'areaOp3EfEvapAcucar'],
      x: 45, y: 24.6, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap4EfEvapAcucar', 'areaOp4EfEvapAcucar'],
      x: 60, y: 24.6, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['taxaEvap5EfEvapAcucar', 'areaOp5EfEvapAcucar'],
      x: 75, y: 24.6, align: 'center', valign: 'flex-end'
    },
    { 
      flow: 'Caldo Cl. Açúcar',
      props: ['flowCaldoClarifAcucar','brixCaldoClarifAcucar','polCaldoClarifAcucar','artCaldoClarifAcucar','tempCaldoClarifAcucar','artMassaCaldoClarifAcucar'],
      x: 2, y: 55, align: 'left', valign: 'flex-start'
    },
  ]
}

const fermentacao = {
  imagem: FermFlx,
  flows: [
    { 
      flow: 'Xarope',
      props: ['flowXaropeToFerm', 'brixXarope', 'tempXarope'],
      x: 87 , y: 3.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Caldo',
      props: ['flowCaldoToFerm', 'brixCaldoToFerm', 'tempCaldoToFerm'],
      x: 2.3, y: 3.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Mel Final',
      props: ['flowMelFinal', 'brixMelFinal', 'tempMelFinal'],
      x: 2.3, y: 11.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Caldo 3º Efeito',
      props: ['flowSangriaCaldo3Ef', 'brixCaldo3EfAc', 'tempSangriaCaldo3Ef'],
      x: 2.3, y: 18.8, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Água Diluição',
      props: ['flowAguaDilMosto', 'tempAguaDilMosto'],
      x: 87, y: 11.4, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Mosto',
      props: ['flowMosto', 'brixMosto', 'artMassaMosto', 'tempMosto'],
      x: 52.8, y: 26, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['tempMostoReg'],
      x: 51.6, y: 32.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Mosto Resf.',
      props: ['flowMosto', 'tempMostoResf'],
      x: 52.8, y: 42, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinho Bruto',
      props: ['flowVinhoBruto', 'concEtanolVinhoBruto', 'concCelVinhoBruto'],
      x: 52.8, y: 60.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinho',
      props: ['flowVinhoCent','concEtanolVinhoBruto','concCelVinhoCent'],
      x: 87, y: 72.4, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Creme',
      props: ['flowCreme','concCelCreme'],
      x: 41.5, y: 64.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Ferm. Trat.',
      props: ['flowfermTratado','concEtanolFermTratado','concCelFermTratato'],
      x: 34.4, y: 53.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'CO2',
      props: ['flowCO2'],
      x: 32, y: 38.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Água',
      props: ['flowAguaLavCO2'],
      x: 2.4, y: 38.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaLavCO2'],
      x: 3.4, y: 52, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Água',
      props: ['flowAguaComplemDilFerm'],
      x: 2.4, y: 64.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consAguaTratDiluicaoFerm'],
      x:13.6, y: 64.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Sangria',
      props: ['flowVolSangriaLev'],
      x: 34.4, y: 74, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaResfMosto', 'tempAguaEntResfMosto'],
      x: 87, y: 36, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaResfMosto', 'tempAguaSaiResfMosto'],
      x: 87, y: 41.2, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaTorreFerm', 'tempAguaFriaTorreFerm'],
      x: 87, y: 50.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaTorreFerm', 'tempAguaQuenteTorreFerm'],
      x: 87, y: 55.8, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoAcucar', 'tempCaldoAcucar'],
      x: 87, y: 26, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowCaldoAcucar', 'tempOutCaldoRegCaldoMosto'],
      x: 87, y: 31, align: 'right', valign: 'flex-start'
    },
  ]
}

const destilaria = {
  imagem: DestFlx,
  flows: [
    { 
      flow: 'Água',
      props: ['flowAguaTorreDestilatia', 'tempAguaQuenteTorreFerm'],
      x: 2 , y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaTorreColA'],
      x: 24.5, y: 10, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaTorrePeneriraMol'],
      x: 79, y: 10, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Vinho',
      props: ['flowVolVinhoCent', 'concEtanolVinhoBruto'],
      x: 2, y: 74.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Vinhaça',
      props: ['flowVinhaca', 'concEtanolVinhaca', 'tempVinhaca'],
      x: 2, y: 83.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanol'],
      x: 52, y: 25, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowEtanolHid', 'flowEtanolHidDia'],
      x: 89.4, y: 55.5, align: 'center', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowEtanolAnidro', 'flowEtanolAnidroDia'],
      x: 89.4, y: 42.8, align: 'center', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['flowVaporVV1Destilaria'],
      x: 87.3, y: 64.5, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VE',
      props: ['flowVaporVEDestilaria'],
      x: 87.3, y: 70.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporTotalColA'],
      x: 29, y: 56.6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporTotalColB'],
      x: 52, y: 50.2, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV1IndColA'],
      x: 75.4, y: 67.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVV1DirColA'],
      x: 58.6, y: 67.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Flegmaça',
      props: ['flowFlegmaca', 'concEtanolFlegmaca'],
      x: 87.3, y: 83.4, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Flegma',
      props: ['flowFlegma', 'concEtanolFlegma'],
      x: 26, y: 40, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'VM',
      props: ['consVaporV22Desidratacao'],
      x: 87.7, y: 24.2, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VE',
      props: ['consVaporVEDesidratacao'],
      x: 87.7, y: 31.2, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Cond.',
      props: ['flowRetCondColA'],
      x: 87.7, y: 75.7, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowEtanolProdToHid'],
      x: 75, y: 58.6, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanolProdToDesid'],
      x: 58.5, y: 25, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowEtanolTanqueToDesid'],
      x: 75, y: 52.4, align: 'right', valign: 'flex-end'
    }
  ]
}

const fabrica = {
  imagem: FabFlx,
  flows: [
    { 
      flow: '',
      props: ['consVaporVV2CozedorMassaA'],
      x: 12.2 , y: 12, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVV2CozedorMassaB'],
      x: 48.6, y: 12, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'VV2',
      props: ['consVapTotalVV2Cozimento'],
      x: 3.3, y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['consVapTotalVV1Cozimento'],
      x: 3.3, y: 17.8, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Cond. VG',
      props: ['consCondVGMelAPobre'],
      x: 48.5, y: 45.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Cond. VG',
      props: ['consCondVGCintrifMassaA'],
      x: 30.2, y: 60.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Cond. VG',
      props: ['consCondVGMagma'],
      x: 59.6, y: 61.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Cond. VG',
      props: ['consCondVGCintrifMassaB'],
      x: 66.5, y: 43.3, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Xarope',
      props: ['flowXaropeToFab', 'brixXarope', 'purezaXarope', 'artMassaXaropeToFab'],
      x: 3.3, y: 32.1, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Xarope p/ MA',
      props: ['flowXaropeToMassaA','brixXarope','purezaXarope'],
      x: 14, y: 32.1, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Xarope p/ MB',
      props: ['flowXaropeToMassaB','brixXarope','purezaXarope'],
      x: 14, y: 43.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaMassaBTorreFab'],
      x: 80.7, y: 8.3, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaQuenteMassaBTorreFab'],
      x: 80.7, y: 16.2, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaMassaATorreFab'],
      x: 39.6, y: 8.3, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaQuenteMassaATorreFab'],
      x: 39.6, y: 16.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowMAPDiluido', 'brixMAPDiluido', 'purezaMAPDiluido'],
      x: 58.4, y: 48, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Açúcar',
      props: ['flowAcucar', 'flowAcucarScsdia', 'brixAcucar', 'purezaAcucar', 'artMassaAcucar'],
      x: 87, y: 83.5, align: 'right', valign: 'flex-end'
    },
    { 
      flow: 'Mel Final',
      props: ['flowMelFinal', 'brixMelFinal', 'purezaMelFinal', 'artMassaMelFinal'],
      x: 87, y: 52.6, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Açúcar B',
      props: ['flowAcucarB',  'brixAcucarB', 'purezaAcucarB'],
      x: 72.6, y: 65, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Mel A Pobre',
      props: ['flowMelAPobre', 'brixMelAPobre', 'purezaMelAPobre'],
      x: 41, y: 51, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Massa A',
      props: ['flowMassaA', 'brixMassaA', 'purezaMassaA'],
      x: 36.4, y: 28, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Massa B',
      props: ['flowMassaB', 'brixMassaB', 'purezaMassaB'],
      x: 72.5, y: 28, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAlimMassaA', 'brixAlimMassaA', 'purezaAlimMassaA'],
      x: 8.4, y: 20, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAlimMassaB', 'brixAlimMassaB', 'purezaAlimMassaB'],
      x: 42.3, y: 20, align: 'right', valign: 'flex-start'
    },
    { 
      flow: 'Magma',
      props: ['flowMagma', 'brixMagma', 'purezaMagma'],
      x: 50, y: 65.4, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VE',
      props: ['consVaporVESecadorAcucar'],
      x: 87, y: 70, align: 'right', valign: 'flex-end'
    }
  ]
}

const vapor = {
  imagem: vapFlx,
  flows: [
    { 
      flow: '',
      props: ['gerVaporVMCaldeiras', 'pressureVM', 'tempVaporVM'],
      x: 8.8 , y: 4.3, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consCondDesCaldeirasVM', 'tempAguaAlimCaldeirasVM'],
      x: 24.6, y: 17, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consBagCaldeirasVM'],
      x: 1.6, y: 20, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM'],
      x: 39.3, y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerVaporVETgCpVM', 'pressEscTgCpVM', 'tempEscTgCpVM'],
      x: 36.3, y: 20, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['ptTgCpVM'],
      x: 36, y: 12.3, align: 'center', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['eficIsoTgCpVM'],
      x: 29.9, y: 15.2, align: 'center', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVEEvapAc'],
      x: 52.6, y: 30, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVEDesaerador'],
      x: 29.5, y: 30, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Alívio',
      props: ['consVaporVEAlivio'],
      x: 89, y: 23, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVEDestilaria'],
      x: 83, y: 30, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVEDesidratacao'],
      x: 75.2, y: 30, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVV1',],
      x: 52.6, y: 39.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV1Aquecimento'],
      x: 17.3, y: 46, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVV1Destilaria'],
      x: 41.1, y: 46, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV12Efeito'],
      x: 64, y: 46, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV1CozedorMassaA'],
      x: 87, y: 46, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Alívio',
      props: ['consVaporVV1Alivio'],
      x: 96.8, y: 39, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVV2Aquecimento'],
      x: 29, y: 61, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV23Efeito'],
      x: 52, y: 61, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV2CozedorMassaA'],
      x: 75, y: 61, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporVV2CozedorMassaB'],
      x: 87, y: 61, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Alívio',
      props: ['consVaporVV2Alivio'],
      x: 96.8, y: 54, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVV3Aquecimento'],
      x: 41, y: 75.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVV34Efeito'],
      x: 64, y: 75.5, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVMPerdas'],
      x: 96.5, y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVEPerdas'],
      x: 96.5, y: 23.1, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVV1Perdas'],
      x: 96.5, y: 46.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVV2Perdas'],
      x: 96.5, y: 61.4, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Perdas',
      props: ['consVaporVV3Perdas'],
      x: 96.5, y: 75.8, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerVaporVEDessupVE'],
      x: 15, y: 30.2, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowVaporVEAcion'],
      x: 58.3, y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowVaporVEAcion', 'pressureVE', 'tempVaporVEAcion'],
      x: 55.3, y: 20, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporV22Desidratacao'],
      x: 86.9, y: 4.6, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consVaporV10Desidratacao'],
      x: 86.9, y: 10, align: 'left', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['consCondVEDessupDesid'],
      x: 78.5, y: 8.2, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['flowAguaDesaerador', 'tempDesaerador'],
      x: 8.8, y: 32.4, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['gerCondVEProcesso', 'tempRetCondDesaerador'],
      x: 35.8, y: 33.3, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Cond. VG',
      props: ['consCondVGDesaerador', 'tempCondVGDesaerador'],
      x: 35.8, y: 37, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Desmi',
      props: ['consAguaDesmiDesaerador', 'tempsAguaDesmiDesaerador'],
      x: 7.3, y: 37, align: 'right', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['consVaporVMRebVMVA'],
      x: 74.1, y: 6, align: 'left', valign: 'flex-start'
    },
    { 
      flow: '',
      props: ['flowAguaDesRebVMVA'],
      x: 66.7, y: 21.8, align: 'right', valign: 'flex-end'
    },
    { 
      flow: '',
      props: ['gerVaporVERebVMVA'],
      x: 74.1, y: 25, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'Bagaço',
      props: ['flowBagacoToBoilers'],
      x: 1.4, y: 14.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'Sobra',
      props: ['sobraBagaco'],
      x: 1.4, y: 20, align: 'left', valign: 'flex-end'
    },
    { 
      flow: 'VM',
      props: ['pressureVM', 'tempVaporVM'],
      x: 1.4, y: 3.5, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VE',
      props: ['pressureVE', 'tempVaporVE'],
      x: 1.4, y: 28.3, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV1',
      props: ['pressureVV1'],
      x: 1.4, y: 44.1, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV2',
      props: ['pressureVV2'],
      x: 1.4, y: 59.2, align: 'left', valign: 'flex-start'
    },
    { 
      flow: 'VV3',
      props: ['pressureVV3'],
      x: 1.4, y: 73.5, align: 'left', valign: 'flex-start'
    },
  ]
}

const bh = {
  imagem: bhFlx,
  flows: [
    {flow: '', props: ['gerCondVGAqVV3Acucar'], x: 95.6, y: 9.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVFLAcucar'], x: 95.6, y: 5.6, align: 'right', valign: 'flex-end'},
    // {flow: '', props: ['gerCondVGAqVV3Acucar2'], x: 79.8, y: 19.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV2Acucar'], x: 95.6, y: 13.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Acucar'], x: 95.6, y: 17.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqCCAcucar'], x: 95.6, y: 21.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV2Etanol'], x: 95.6, y: 25.4, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Etanol'], x: 95.6, y: 29.4, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGEvapAc'], x: 95.6, y: 33.4, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerCDVGFabrica'], x: 95.6, y: 37.3, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCondVegetal'], x: 81.5, y: 23.5, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['flowCDVGtoTqCaldeira'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['flowSobraCDVGTqCaldeira'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['flowCondContaminado'], x: 70, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVEProcesso'], x: 81.3, y: 8, align: 'left', valign: 'flex-end'},
    //// {flow: '', props: ['consCondVGDesaerador'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: 'VE', props: ['consVaporVEDesaerador'], x: 70, y: 8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaDesmiDesaerador'], x: 63.5, y: 16, align: 'right', valign: 'flex-start'},
    {flow: 'Vapor', props: ['gerVaporVMCaldeiras'], x: 73.2, y: 28.9, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaDesaerador'], x: 81.3, y: 16, align: 'left', valign: 'flex-end'},
    {flow: 'Dessup.', props: ['flowCondDessupFromDes'], x: 73.2, y: 20.9, align: 'right', valign: 'flex-end'},
    //// {flow: '', props: ['consCondVECaldeira1'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaResfMosto'], x: 22.5, y: 39.4, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreFerm'], x: 22.5, y: 52, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTotalTorreDest','tempAguaQuenteTotalTorreDest'], x: -5.8, y: 40, align: 'right', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTotalTorreDest', 'tempAguaFriaTorreFerm'], x: 27.2, y: 40, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaRepTorreDest'], x: 22.4, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreDest'], x: 22.4, y: 58.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaEvapTorreDest'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaEvapTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaMassaATorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaMassaBTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteEvapTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteMassaATorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteMassaBTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaQuenteTorreFab','tempAguaQuenteTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreFab', 'tempAguaFriaTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consAguaBrutaRepTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaArrasteTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaEvapTorreFab'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreMancais','tempAguaQuenteTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowAguaTorreMancais', 'tempAguaFriaTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consAguaBrutaRepTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaArrasteTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowPerdaEvapTorreMancais'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaComplemDilFerm'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaLavCO2'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratPrepPolimeto'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratLevedura'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratAbrandador'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratLimpEvap'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratEvap'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratGeral'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratPrepMosto'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},

    {flow: '', props: ['flowAguaBrutaTotal'], x: -8.5, y: 2.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaETA'], x: -8.5, y: 9.2, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaGeral'], x: -8.5, y: 71.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaEmb'], x: -8.5, y: 63.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowFlegmaca'], x: 43.3, y: 52, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowVinhaca', 'tempVinhacaReg'], x: 84.8, y: 70.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreVinhaca'], x: 78.6, y: 69, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaEvapTorreVinhaca'], x: 100.8, y: 69, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVinhacaFria', 'tempVinhacaFriaTorreVinhaca'], x: 106, y: 70.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreDest'], x: 34, y: 24.2, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreFab'], x: 34, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreMancais'], x: 34, y: 13.9, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResAbrandador'], x: 64.6, y: 20.9, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['gerAguaResLimpEvap'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeral'], x: 43.2, y: 26.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeralAguaBruta'], x: 16, y: 71.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['gerAguaResUsoGeralCDCont'], x: 81.3, y: 40, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResSobraCondCont'], x: 66.9, y: 58.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResDescCaldeira'], x: 81.3, y: 38, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResFiltroLodo'], x: 85.5, y: 58.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResETA'], x: 16, y: 9.1, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaResParaLavoura'], x: 115.5, y: 66, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContFiltroLodo'], x: 76, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContUsoGeral'], x: 85.5, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCDVGFabrica'], x: 95, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContPrepLeiteCal'], x: 104.6, y: 48.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContEmbebicao'], x: 114.2, y: 48.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consCondContDilMosto'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consAguaResRepSistFuligem'], x: 2.4, y: 78.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaLavadorGases'], x: 63.2, y: 75, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaEvapLavadorGases'], x: 52, y: 67.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaFuligem'], x: 50.4, y: 82.6, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowTotalToLavoura'], x: 115.5, y: 77, align: 'left', valign: 'flex-end'},
  ]
}