import React, { useContext } from 'react'
import MenuLateral from './MenuLateral/MenuLateral'
import BarraSuperior from './BarraSuperior/BarraSuperior'
import Conteudo from './Conteudo/Conteudo'
import '../App.css'
import GlobalStateContext from '../components/GlobalStateContext'
import ConteudoPS from './PlanoSafra/ConteudoPS'
import { listaPS, listBME } from '../Resources/Constantes'

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Fluxograma from './Conteudo/Fluxograma'
import Limites from './Conteudo/Limites/Limites'
import Alertas from './Conteudo/Alertas/Alertas';
import SegmentedControl from './Conteudo/SegmentedControl'
import Listas from './Conteudo/Listas'

function Main() {
  const {area} = useContext(GlobalStateContext)

  return (
    <div className='main'>
     <BrowserRouter>
     {/* <Menu/> */}
        <MenuLateral/>
        <div className='rightSide'>
            <BarraSuperior/>
            {area !== 'resumoProd' && area !== 'alertas' && area !== 'limites' && <SegmentedControl />}
              <Routes>
                <Route path='/resumoProd' element={<Listas/>}/>
                {/* <Route path='/extracao/fluxograma' element={<Fluxograma/>}/>
                <Route path='/tratAcucar' element={<Conteudo/>}/>
                <Route path='/tratEtanol' element={<Conteudo/>}/>
                <Route path='/filtLodo' element={<Conteudo/>}/>
                <Route path='/evaporacao' element={<Conteudo/>}/>
                <Route path='/fermentacao' element={<Conteudo/>}/>
                <Route path='/destilaria' element={<Conteudo/>}/>
                <Route path='/fabrica' element={<Conteudo/>}/>
                <Route path='/vapor' element={<Conteudo/>}/>
                <Route path='/balHidrico' element={<Conteudo/>}/> */}
                <Route path='/alertas' element={<Alertas/>}/>
                <Route path='/limites' element={<Limites/>}/>
                <Route path='/planoSafra' element={<ConteudoPS/>}/>
                <Route path={'/'+area+'/fluxograma'} element={<Fluxograma/>}/>
                <Route path={'/'+area+'/premissas'} element={<Listas/>}/>
                <Route path={'/'+area+'/fluxos'} element={<Listas/>}/>
                <Route path={'/'+area+'/equipamentos'} element={<Listas/>}/>
                {/* <Route path='/tratAcucar/fluxograma' element={<Fluxograma/>}/>
                <Route path='/tratEtanol/fluxograma' element={<Fluxograma/>}/> */}
                
              </Routes>

            {/* {(listBME.includes(area) || area === 'limites') && <Conteudo/>}
            {listaPS.includes(area) && <ConteudoPS/>} */}
            
        </div>
        </BrowserRouter>
    </div>
  )
}

export default Main