// components


import {useEffect, useContext } from 'react';

import './App.css';
import Main from './components/Main';
import  GlobalStateContext  from './components/GlobalStateContext'
import { getAlertList } from './Funcoes/GetAletrsList';
import { useFetch } from './Hooks/useFetch';

function App() {
  const {setData, data, setlistAlerts, setUser} = useContext(GlobalStateContext)

  const userDev = {
    profile: 'developer',
    id: 1,
    email: 'emalmeida@gmail.com',
    firstName: 'Eduardo',
    lastName: 'Almeida',
    units: ['all']
  }

  // const { data2 } = useFetch('HTTP://localhost:7096/1/getAll')

  // setData(data2)

  useEffect(() => {
    console.log('Initial Execution')
    setUser(userDev)
    setlistAlerts(getAlertList(data))
    const fetchData = async () => {
      try {
        const response = await fetch('HTTP://localhost:7096/1/getAll');
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }
        const jsonData = await response.json();
        console.log('JSON')
        setData(jsonData);
        setlistAlerts(getAlertList(data))
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    }
    fetchData();
  }, [setData, setlistAlerts]); // O segundo argumento vazio significa que o useEffect será executado apenas uma vez, após a montagem do componente

  return (
      <div className="App">
        <div className='container'>
          <Main/>
        </div>
      </div>
  )
}

export default App;

