
function AlertsLine({item}) {
    return (
        <div style={{marginLeft: '2vh', marginRight: '2vh', backgroundColor: 'white', borderBottom: '1px solid #f2f2f7'}}>
            <div className='fullLine'>
                <div className='fullLineRow' style={{width: '20%'}}>{item?.descricao}</div>
                <div className='fullLineRow' style={{width: '20%'}}>{item?.valor.toFixed(2)}</div>
                <div className='fullLineRow' style={{width: '20%'}}>{item?.limiteMin !== null ? item?.limiteMin.toFixed(2) : ''}</div>
                <div className='fullLineRow' style={{width: '20%'}}>{item?.limiteMax !== null ? item?.limiteMax.toFixed(2) : ''}</div>
                <div className='fullLineRow' style={{width: '20%', textAlign: 'right'}}>{item?.unidade}</div>
            </div>
        </div> 
        )  

}

export default AlertsLine