import React from 'react'
import GlobalStateContext from '../../GlobalStateContext'
import { useContext } from 'react'
import AlertsLine from './AlertsLine'

function Alertas() {
const {listAlerts} = useContext(GlobalStateContext)

  return (
    <div style={{overflowY: 'scroll', width: '80%', margin: 'auto', marginTop: '1vh'}}>
        <div style={{marginLeft: '2vh', marginRight: '2vh', marginTop: '2vh', marginBottom: '0.5vh', fontWeight: 'bold'}}>
            <div className='fullLine'>
                <div className='fullLineRow' style={{width: '20%'}}>Desrição</div>
                <div className='fullLineRow' style={{width: '20%'}}>Valor</div>
                <div className='fullLineRow' style={{width: '20%'}}>Mínimo</div>
                <div className='fullLineRow' style={{width: '20%'}}>Máximo</div>
                <div className='fullLineRow' style={{width: '20%', textAlign: 'right'}}>Unidade</div>
            </div>
      </div>
        {listAlerts.map((item) => {
              return (<AlertsLine item={item} key={item.codigo}/>)
        })}
    </div>
  )
}

export default Alertas