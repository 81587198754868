import React from 'react'
import '../../css/InputLine.css'
import InputLine from './InputLine'
import OutputLine from './OutputLine'
import ComboLine from './ComboLine'
import SwitchLine from './SwitchLine'

function ListLine({ipt, cod, handleCountChange, key }) {
    if (ipt === true){
        return (
            <InputLine cod={cod} key={key}/>
        )
    }else if(ipt === false){
        return(
            <OutputLine cod={cod} key={key}/>
        )
    }else if(ipt === 'swicth'){
        return(
            <SwitchLine cod={cod} key={key} handleCountChange={handleCountChange}/>
        )
    }else{
        if (ipt === 'combo'){
            return(<ComboLine cod={cod} key={key} handleCountChange={handleCountChange}/> )
        }else{
            return(
                 <></>
            )
        }
    } 
}

export default ListLine