import React, { useContext } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'

function OutputLine({cod}) {
  const { data } = useContext(GlobalStateContext);
  return (
    <div className={`inputLineCont ${data[cod]?.limiteMax !== null && data[cod]?.valor > data[cod]?.limiteMax ? 'outOfLimit' : ''}`} >
            <div className='line'>
                <div className='desc'>{data[cod]?.descricao}</div>
                <div className='optCont'>{data[cod]?.valor !== null ? data[cod]?.valor.toFixed(2) : data[cod]?.valor}</div>
                <div className='unit'>{data[cod]?.unidade}</div>
            </div>
    </div>
  )
}

export default OutputLine