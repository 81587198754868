import React from 'react'
import { useContext } from 'react'
import GlobalStateContext from '../GlobalStateContext'

function Flow({flow, x, y, align, valign, cods}) {
    const {area, data} = useContext(GlobalStateContext)
    const style = {
        position: 'absolute',
        height: '1.5vh',
        width: '15vh',
        left: `${x}vh`,
        top: `${y}vh`,
        // border: '1px solid red',
        fontSize:'1.5vh',
      };

      function getAbrev(cod){
        if (area === 'balHidrico'){
            return ''
        }
        let abrev = cod.substring(0,3)
        switch (abrev){
            case 'pol': return 'Pol'
            case 'pur': return 'Pur'
            case 'fib': return 'Fib'
            case 'arC': return 'Ar'
            case 'art': if (cod.substring(0,8) !== 'artMassa'){return 'Art'}else{break}
            case 'bri': return 'Bx'
            default: return ''
        }
    }

  return (
    <div style={style}>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: `${valign}`, gap: '0'}}>
        {flow !== '' ? 
        <p style={{margin: '0', textAlign: `${align}`, fontWeight: 'bold'}}>{flow}</p> :
        <></>}
        {data && cods.map((cod, index) => {
            return (
                <p key={''+cod+''+index} style={{margin: '0', textAlign: `${align}`, 
                color: `${(((data[cod].valor < data[cod].limiteMin) && (data[cod].limiteMin !== null)) || ((data[cod].valor > data[cod].limiteMax) && (data[cod].limiteMax !== null))) && 'red'}`}}>
                    {data[cod].valor.toFixed(area === 'balHidrico' && data[cod].valor > 1000 ? 0 : 2)} {area !== 'balHidrico' ? data[cod].unidade : ''}{getAbrev(cod)}</p>
            )
        })}
        </div>
      </div>
  )
}

export default Flow

