import React, { useState } from 'react';
import GlobalStateContext from '../components/GlobalStateContext';
import { useContext, useEffect } from 'react';
import { useFetch } from '../Hooks/useFetch';
import { getAlertList } from '../Funcoes/GetAletrsList';


const FileUploader = () => {
    const {setData, setlistAlerts} = useContext(GlobalStateContext); // Obtém a função de atualização do estado do contexto
    // const { data: updatedData } = useFetch('HTTP://localhost:7096/1/getAll')
    const [upt, setUpt] = useState(false)

    useEffect(() => {
        // console.log('Initial Execution')
        // setUser(userDev)
        // setlistAlerts(getAlertList(data))
        const fetchData = async () => {
          try {
            const response = await fetch('HTTP://localhost:7096/1/getAll');
            if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
            }
            const jsonData = await response.json();
            console.log('JSON')
            setData(jsonData);
            setlistAlerts(getAlertList(jsonData))
          } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
          }
        }
        fetchData();
      }, [upt]);

    const handleFileChange =  (event) => {
        const fileInput = document.getElementById('fileInput')
        console.log('Start importing')
      const file = event.target.files[0];
      if (file) {
        if (file.type === 'application/json') {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const jsonContent = e.target.result;
            const jsonData = JSON.parse(jsonContent);

            for (const key in jsonData) {
            if (Object.hasOwnProperty.call(jsonData, key)) {
                const value = jsonData[key];
                // Aqui você pode fazer o que precisar com cada chave e valor
            //   console.log('Chave:', key, 'Valor:', value);
                await setValueToDb(value.cod, value.value)
            }
            }

  
            // Atualiza o estado com os dados do arquivo JSON usando a função fornecida pelo contexto
            // setData(updatedData);
            setUpt(!upt)
            global.lastFile = fileInput.value
            fileInput.value = ''
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecione um arquivo JSON.');
        }
      }
    };
  
    return (
        <div>
        {/* Botão personalizado */}
        <label htmlFor="fileInput" className="custom-file-upload">
          Escolher arquivo
        </label>
        <label>{global.lastFile}</label>
        {/* Botão de arquivo oculto */}
        <input id="fileInput" type="file" accept=".json" onChange={handleFileChange} style={{ display: 'none' }} />
      </div>
    );
  };
  
  export default FileUploader;



const setValueToDb = async (cod, v) => {
        await fetch('HTTP://localhost:7096/1/setValue', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codigo: cod, newValue: parseFloat(v)})
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
        //   console.log('updated: ')
        //   data[cod].valor = parseFloat(v)
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
        console.log('Erro: '+cod+' :'+v)
      })
  }

//   const updateDb = async () => {
//     const {setData, data, setlistAlerts} = useContext(GlobalStateContext)
//     try {
//         const response = await fetch('HTTP://localhost:7096/1/getAll');
//         if (!response.ok) {
//           throw new Error('Erro ao buscar dados da API');
//         }
//         const jsonData = await response.json();
//         setData(jsonData);
//         setlistAlerts(getAlertList(data))
//       } catch (error) {
//         console.error('Erro ao buscar dados da API:', error);
//       }
// }


