import React, { useContext } from 'react';
import '../../css/SegmentedControl.css'; 
import GlobalStateContext from '../GlobalStateContext'
import { NavLink, Link } from 'react-router-dom';


function SegmentedControl() {
  const {screen, setScreen, area} = useContext(GlobalStateContext);
  
  const handleOptionChange = (option) => {
    setScreen(option)
    if (area === 'extracao' && option === 'premissas'){
        setScreen('fluxograma')
    }
  };

  const premissasAreas = ['vapor', 'fabrica', 'balHidrico', 'destilaria']
  const fluxosAreas = ['extracao', 'tratAcucar', 'tratEtanol', 'evaporacao', 'fermentacao', 'fabrica']

  return (
    <nav>
      <div className={`seg-container ${screen === 'fluxograma' ? 'fluxograma' : ''}`} >
      <div className="segmented-control">

      <Link className={`segmented-control-button ${screen === 'fluxograma' ? 'selected' : 'unselected'} first-button`} 
      to={'/'+area+'/fluxograma'} 
      onClick={() => handleOptionChange('fluxograma')}>{'    Fuxograma    '}</Link>

      {premissasAreas.includes(area) ?
       <Link className={`segmented-control-button ${screen === 'premissas' ? 'selected' : 'unselected'} middle-button`} 
       to={'/'+area+'/premissas'} onClick={() => handleOptionChange('premissas')}>{'   Premissas   '}</Link> : <p></p>}

      {fluxosAreas.includes(area) ?
      <NavLink className={`segmented-control-button ${screen === 'fluxos' ? 'selected' : 'unselected'} middle-button`} 
      to={'/'+area+'/fluxos'} onClick={() => handleOptionChange('fluxos')}>{'   Fluxos   '}</NavLink>: <p></p>}
      
      <NavLink className={`segmented-control-button ${screen === 'equipamentos' ? 'selected' : 'unselected'} last-button`} 
      to={'/'+area+'/equipamentos'} onClick={() => handleOptionChange('equipamentos')}>{' Equipamentos '}</NavLink>
   
{/* 
      <button
        className={`segmented-control-button ${screen === 'equipamentos' ? 'selected' : 'unselected'} last-button`}
        onClick={() => handleOptionChange('equipamentos')}
      >
        Equipamentos
      </button> */}
    </div>
    </div>
    </nav>
    
  );
}

export default SegmentedControl;


