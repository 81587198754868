// GlobalStateContext.js
import React, { createContext, useState } from 'react';

// Criando o contexto
export const GlobalStateContext = createContext();

// Provedor de contexto
export const GlobalStateProvider = ({ children }) => {
  const [isAlertOn, setAlert] = useState(true); // Estado global
  const [data, setData] = useState(null);
  const [area, setArea] = useState('resumoProd');
  const [screen, setScreen] = useState('');
  const [listAlerts, setlistAlerts] = useState(null);
  const [user, setUser] = useState(null);
  return (
    <GlobalStateContext.Provider value={{ 
      isAlertOn, setAlert, 
      data, setData,
      area, setArea,
      screen, setScreen, listAlerts, setlistAlerts, user, setUser }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContext;