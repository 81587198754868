import { useState, useContext, useEffect } from 'react'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import styles from './MenuLateral.module.css'
import { IoChevronForwardOutline, IoChevronDown, IoAlertCircleSharp } from "react-icons/io5";
import GlobalStateContext from '../GlobalStateContext'
import { getAlertList } from '../../Funcoes/GetAletrsList';
import { listaPS, listBME } from '../../Resources/Constantes';
import { NavLink} from 'react-router-dom'

function MenuLateral() {
  // const listBME = ['resumoProd', 'extracao', 'tratAcucar', 'tratEtanol', 'filtLodo', 'evaporacao', 'fermentacao', 'destilaria', 'fabrica', 'vapor', 'balHidrico', 'alertas']
  // const listPS = ['planoSafra', 'comparativoPs', 'plano10Anos']
  const [showMenuBme, setShowMenuBme] = useState(true)
  const [showMenuPS, setShowMenuPS] = useState(false)
  const {area, setArea, screen, setScreen, listAlerts, user, setlistAlerts, data} = useContext(GlobalStateContext)

  const showBME = () => {
    setShowMenuBme(!showMenuBme)
  }

  const showPS = () => {
    setShowMenuPS(!showMenuPS)
  }

  const handleMenuChanged = (item) => {
    setArea(item)
    setScreen('fluxograma')
    if (item === 'resumoProd'){
      setScreen('fluxos')
    }
  }

  useEffect(() => {
    setlistAlerts(getAlertList(data))
  }, [data]);

  const alertIcon = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <p className={styles.p_Item} style={{color: 'red', fontWeight: 'bold'}}>
           <IoAlertCircleSharp style={{color: 'red', fontWeight: 'bold', 
          marginTop: '0.5vh', fontSize: '2.5vh', marginRight: '1vh', marginLeft: '-0.5vh'}}/>
           </p>
      </div>
    )
  }

  return (
    <div className='menuLateral'>
      <div className={styles.menu_Logo}>
        <img className={styles.logo_Costumer} src="/logoCostumer.png" alt="" />
      </div>
      <div>
        <div onClick={showBME} className={styles.menu_Head}>
            <p>Balanço de Massa e Energia</p>
            {showMenuBme ? 
            <IoChevronDown className={styles.icon_Head}/> :
            <IoChevronForwardOutline className={styles.icon_Head}/>
            }
        </div>
        
        <div className={!showMenuBme ? styles.show_menuBme : ''}>
        <nav>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
           <NavLink className={({isActive}) => (isActive ? styles.sel_Item : styles.unsel_Item)} 
                to='/resumoProd' onClick={() => handleMenuChanged('resumoProd')}>Resumo de Produção</NavLink>
          </div>
       
          {listBME.map((item, index) => {
            return (
              
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} 
                to={'/'+item+'/fluxograma'} onClick={() => handleMenuChanged(item)} 
                key={index}>{getTitleFromArea(item)}</NavLink>
                
               
                </div>
            )
          })} 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
           <NavLink className={({isActive}) => (isActive ? styles.sel_Item : styles.unsel_Item)} 
                to='/alertas' onClick={() => handleMenuChanged('alertas')}>Alertas</NavLink>
                 {(listAlerts?.length > 0) && <alertIcon/>}
          </div>

          {(user?.profile === 'developer' || user?.profile === 'admin') && 
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
              <NavLink className={area === 'limites' ? styles.sel_Item : styles.unsel_Item} to={'/limites'} onClick={() => handleMenuChanged('limites')}>Limites</NavLink>
            </div>
          }
          </nav>
        </div>
         
      </div>
      <div>
        <div onClick={showPS} className={styles.menu_Head}>
            <p>Planejamento Safra</p>
            {showMenuPS ? 
            <IoChevronDown className={styles.icon_Head}/> :
            <IoChevronForwardOutline className={styles.icon_Head}/>
            }
        </div>
        
        <div className={!showMenuPS ? styles.show_menuBme : ''}>
        <nav>
          {listaPS.map((item, index) => {
            return (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} to={'/'+item} onClick={() => handleMenuChanged(item)} key={index}>{getTitleFromArea(item)}</NavLink>
                  {listAlerts?.length > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  {item === 'alertas' ? <p className={styles.p_Item} 
                  style={{color: 'red', fontWeight: 'bold'}}>
                    {listAlerts?.length}</p> : <></>}
                    {item === 'alertas' ? <IoAlertCircleSharp  style={{color: 'red', fontWeight: 'bold', 
                    marginTop: '0.5vh', fontSize: '2.5vh', marginRight: '1vh', marginLeft: '-0.5vh'}}/> : <></>}
                </div>}
                  </div>
            )
          })}
          </nav>
        </div>
         
      </div>
    </div>
  )
}

export default MenuLateral

