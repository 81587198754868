function getAlertList(data){
  let listAlerts = []
    if (!data){
      return listAlerts
    }
    let listKeys = Object.keys(data)
    
    listKeys.map((item) => {
        if (data[item].limiteMin !== null || data[item].limiteMax !== null){
            if (data[item].valor < data[item].limiteMin ||  data[item].valor > data[item].limiteMax){
            listAlerts.push(data[item])
              }
            }
    })
    

    return listAlerts
}


module.exports = {
    getAlertList
  }