import React, { useContext } from 'react'

import '../../css/Conteudo.css';
import GlobalStateContext from '../GlobalStateContext'


function ConteudoPS() {
  const {area, screen, data} = useContext(GlobalStateContext)

  return (
    <div className='conteudo'>
        Plano Safra
    </div>
  )
}

export default ConteudoPS