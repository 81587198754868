function getTitleFromArea(title){
    switch (title){
      case 'resumoProd': return 'Resumo de Produção'
      case 'extracao': return 'Extração do Caldo'
      case 'tratAcucar': return 'Tratamento Fábrica'
      case 'tratEtanol': return 'Tratamento Destilaria'
      case 'filtLodo': return 'Filtração de Lodo'
      case 'evaporacao': return 'Evaporação'
      case 'fermentacao': return 'Fermentação'
      case 'destilaria': return 'Destilaria'
      case 'fabrica': return 'Fábrica de Açúcar'
      case 'vapor': return 'Vapor e Energia'
      case 'balHidrico': return 'Balanço Hídrico'
      case 'alertas': return 'Alertas'
      case 'limites': return 'Limites'
      case 'planoSafra': return 'Plano Safra'
      case 'comparativoPs': return 'Comparativo Revisões'
      case 'plano10Anos': return 'Plano 10 Anos'
      default: return ''
    }
  }

  module.exports = {
    getTitleFromArea
  }